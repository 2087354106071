import axios from 'axios';

const randomCategory = () => {
  const category = ['inspire', 'funny'];
  return category[Math.floor(Math.random() * category.length)];
}

function getQuote() {
  return axios.get(`http://quotes.rest/qod.json?category=${randomCategory()}`)
    .then(({data}) => {
      console.log(data)
      const { quote, author } = data.contents.quotes[0]
      return { quote, author }
    })
    .catch(() => ({
      quote: 'Be strong. You got this',
      author: 'me'
    }))
}

export default getQuote;