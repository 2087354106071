import React from 'react';
import classNames from 'classnames';
import './date.css';

const DateComponent = ({vice, current, today, value, onClick}) => {
  const properties = {
    'date-cell': true,
    'today': today,
    'current-month': current,
    'vice': !!vice,
  }

  return (
    <td className={classNames(properties)}>
      <div onClick={onClick}>
        {value}
      </div>
    </td>
  )
}

export default DateComponent;
