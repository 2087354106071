import React from 'react';
import { months } from '../../constants';
import './date-select.css'

function DateSelect({options, year, month, goToMonth}) {
  return (
    <div className="date-selector-container">
      <select value={`${year}-${month}`} onChange={e => {
        const [_year, _month] = e.target.value.split('-');
        goToMonth(_month, _year);
      }}>
        {options.map(opt => {
          let [y, m] = opt.split('-');
          return (
            <option key={`date-select-option-${opt}`} value={opt}>{months[m]} {y}</option>
          );
        })}
      </select>
    </div>

  );
}

export default DateSelect;
