export const SET_QUOTE = 'quote/SET_QUOTE'

const initialState = {
  quote: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_QUOTE:
      return {
        ...state,
        quote: action.payload
      }

    default:
      return state
  }
}

export const setQuote = (quote) => {
  return dispatch => {
    dispatch({
      type: SET_QUOTE,
      payload: quote
    })
  }
}
