import { DEFAULT_DATES } from '../constants';
import { loadVices, saveVices } from '../logic/local-storage';

export const ADD_VICE_TOKEN = 'token/ADD_VICE_TOKEN'
export const REMOVE_VICE_TOKEN = 'token/REMOVE_VICE_TOKEN'
export const GOTO_MONTH = 'calendar/GOTO_MONTH'
export const POPULATE_VALUES = 'data/POPULATE_VALUES'

const initialState = {
  month: (new Date()).getMonth(),
  year: (new Date()).getFullYear(),
  vices: loadVices(DEFAULT_DATES),
  max: {
    booze: 15,
    bud: 15,
  }
}

export default (state = initialState, action) => {
  const { vices } = state;
  switch (action.type) {
    case ADD_VICE_TOKEN:
      const { date, vice } = action.payload;
      if (vice && !vices.includes(date)) {
        vices.push(date);
      } else if (!vice && vices.includes(date)) {
        vices.splice(vices.indexOf(date), 1);
      }
      saveVices(vices)
      return {
        ...state,
        vices,
        // add an entry to state.vices
      }

    // case REMOVE_VICE_TOKEN:
    //   delete vices[action.payload];
    //   saveVices(vices)
    //   return {
    //     ...state,
    //     vices,
    //     // remove an entry from state.vices
    //   }

    case GOTO_MONTH:
      return {
        ...state,
        month: Number(action.payload.month),
        year: Number(action.payload.year),
        // set month
      }

    case POPULATE_VALUES:
      return {
        ...state,
        // set values for variables
      }

    default:
      return state
  }
}

export const populateState = () => {
  return dispatch => {
    dispatch({
      type: POPULATE_VALUES,
    })
  }
}

export const removeViceToken = (date) => {
  return dispatch => {
    dispatch({
      type: REMOVE_VICE_TOKEN,
      payload: date,
    })
  }
}

export const addViceToken = (vice) => {
  return dispatch => {
    dispatch({
      type: ADD_VICE_TOKEN,
      payload: vice,
    })
  }
}

export const goToMonth = (month, year) => {
  return dispatch => {
    dispatch({
      type: GOTO_MONTH,
      payload: { month, year }
    })
  }
}
