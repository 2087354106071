const LOCAL_STORAGE_KEY = 'v1-vice-calendar-vices'

export function loadVices(defaultVices) {
  const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!stored) {
    saveVices(defaultVices)
    return defaultVices;
  }
  return JSON.parse(stored);
}

export function saveVices(vices) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(vices));
}